.FlightsPage {
    margin: 0px 0;
}

.FlightsPage .main-hero-wrap {
    background: #FFF; 
}

.FlightsPage .flights-hero-wrap {
    background: #003B95;
    padding: 5px 0px 0px; 
}

.FlightsPage h2 {
    color: #F5F5F5;
    font-size: 45.82px;
    font-weight: bold;
}

.FlightsPage .flights-hero-wrap h4 {
    color: #FFB700;
    font-size: 35.82px;
}

.FlightsPage .flight-search-panel {
    background: #F0F6FF;
    border-radius: 25px;
    border: 1px solid #FFB700;
    padding: 35px;
}

.FlightsPage .flight-search-panel .form-select,
.FlightsPage .flight-search-panel label {
    margin-top: 5px;
}

.FlightsPage .container-2 h2 {
    color: #003B95;
    margin-bottom: 25px;
}

.FlightsPage .container-2 .travel-deals {
    background:#F0F6FF; 
    width: 90%; 
    border-radius: 25px;
    cursor: pointer;
    color: #FFF; 
    margin: 20px 0;
}

.FlightsPage .container-2 .travel-deals .box-overlay {
    background:rgb(17, 17, 17, 0.35); 
    width: 100%;
    padding: 30px;
    border-radius: 25px;
    cursor: pointer;
    color: #FFF; 
}

.FlightsPage .container-2 .travel-deals .price {
    display: block;
    padding-top: 70px;
    font-size: larger;
    font-weight: bold;
}

.FlightsPage .container-2 .travel-deals .price h5 {
    background-color: #003B95;
    color: #F0F6FF;
    text-align: center;
    padding: 5px;
}

.FlightsPage .container-2 .travel-deals .price p {
    background-color: #FFB700;
    color: #222222;
    text-align: center;
    padding: 5px;
}
 
@media (max-width: 1024px) {
    .FlightsPage .main-hero-wrap { 
        height: auto;
    }
    
    .FlightsPage .flights-hero-wrap { 
        height: auto;
    }
}
@media (max-width: 800px) {
    .FlightsPage .main-hero-wrap { 
        height: auto;
    }
    
    .FlightsPage .flights-hero-wrap { 
        height: auto;
    }
}