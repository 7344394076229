.table-panel {
    padding: 20px;
    border-radius: 7px;
    border: 0.967px solid #FFF;
    background: #FFFBFB;
    box-shadow: 1.9333332777023315px 3.866666555404663px 19.333332061767578px 0px rgba(0, 0, 0, 0.10);
}
tr td {
    font-size: 13px;
}

h2 {
    color: #003B95;
}