.navbar-menu {
    padding: 0;
    margin: 50px 0;
}

.navbar-menu li {
    list-style: none;
    margin: 10px 0;
}

.navbar-menu li a {
    text-decoration: none;
    color: #003B95;
}

.welcome-panel {
    background-color: #FFFBF0;
    padding: 30px;
    border-radius: 21px;
}

.ewallet-panel {
    background-color: #F0F6FF;
    padding: 30px;
    border-radius: 21px;
}

.listing-cta {
    display: block;
    padding: 20px;
    box-shadow: 5px 12px 40px #F0F6FF;
    background-color: #fff;
    border-radius: 12px;
    text-align: center;
}

.listing-cta h4 {
    text-align: center; 
}

.icon-list .icon-big {
    color: #003B95;
    text-align: center;
    margin: 20px auto;
}

.profile-bg {
    display: block;
    padding: 20px;
    box-shadow: 5px 12px 40px #F0F6FF;
    background-color: #fff;
    border-radius: 12px; 
}