.BookingSuccess {
    vertical-align: middle;
    text-align: center;
    margin: 50px auto;
}
.BookingSuccess h2 {
    font-weight: 700;
    font-size: 45.82px; 
    padding-top: 20px;
    color: #003B95;
}

.BookingSuccess .booking-success-panel {
    width: 50%;
    margin: 25px auto;
}

.BookingSuccess .booking-success-panel a {
    text-decoration: none;
}
