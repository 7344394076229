.stats-wrap {
  width: 100%;
  height: auto;
  padding: 35px 0;
  margin: 126px 0;
}

.stats-left {
  background: #F0F6FF;
  box-shadow: 0px 4px 40px #F0F6FF;
  border-radius: 35px; 
  height: auto; 
  margin-right: 50px;
  padding: 35px 35px 0px;
  margin-top: -50px;
}

.stats-right {
  background: #F0F6FF;
  box-shadow: 0px 4px 40px #F0F6FF;
  border-radius: 35px; 
  height: auto;
  padding: 35px;
}

.stats-wrap .stats-icon {
  float: left; 
  font-size: 35px;
  padding: 20px;
  color: #003B95;
  width: 15%;
}

.stats-left .stats-left-text,
.stats-right .stats-right-text {
  float: right;
  width: 85%;
}

.stats-left .stats-left-text h2,
.stats-right .stats-right-text h2 {
  color: #003B95;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .stats-wrap .stats-icon {
    float: left; 
    font-size: 35px;
    padding: 20px;
    color: #003B95;
    width: 25%;
  }
  .stats-left .stats-left-text,
  .stats-right .stats-right-text {
    float: right;
    width: 75%;
  }
}
@media (max-width: 800px) {
  .stats-wrap .stats-icon {
    float: left; 
    font-size: 35px;
    padding: 20px;
    color: #003B95;
    width: 25%;
  }
  .stats-left .stats-left-text,
  .stats-right .stats-right-text {
    float: right;
    width: 75%;
  }
}