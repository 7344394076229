.RefundThanks {
    vertical-align: middle;
    text-align: left;
    margin: 50px 0;
}
.RefundThanks h2 {
    font-weight: 700;
    font-size: 22px;
    padding-top: 20px;
    color: #003B95;
}

.RefundThanks .booking-success-panel {
    padding: 10px;
}

.RefundThanks .btnMore {
    float: left;
}