.navbar-menu {
    padding: 0;
    margin: 50px 0;
}

.navbar-menu li {
    list-style: none;
    margin: 10px 0;
}

.navbar-menu li a {
    text-decoration: none;
    color: #003B95;
}

.welcome-panel {
    background-color: #FFFBF0;
    padding: 30px;
    border-radius: 21px;
}

.ewallet-panel {
    background-color: #F0F6FF;
    padding: 30px;
    border-radius: 21px;
}

.ewallet-box {
    background-color: #F0F6FF;
    padding: 10px 20px;
    border-radius: 21px;
}
