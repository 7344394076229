.login-panel {
    width: 320px;
    height: auto;
    margin: 72px auto;
}

.login-panel h3 {
    font-size: 23px;
}

.login-panel p {
    font-size: 14px;
}

input[type=text], input[type=email], input[type=password]{
    border: 1px solid #e0e4eb;
}

.login-panel .policy {
    border-top: 1px solid #e0e4eb;
    border-bottom: 1px solid #e0e4eb; 
    margin: 30px auto;
}

.login-panel .policy p { 
    padding: 20px 0;  
    font-size: 13px;
}

.login-panel .policy a {
    text-decoration: none;
}

.login-panel .btn {
    display: block;
}