.hero-form { 
    width: 95%;
    padding: 20px;
    background-color: #F0F6FF;
    border: 1px solid #F0F6FF;
    border-radius: 15px;
}

.about-section {
    padding: 20px 0;
}

.about-left {
    margin-top: 20px;
    padding: 20px 0;
}

.about-left h2 {
    font-size: 35px;
}

.highlight, .icon, .icon-big {
  color: #FFB700; 
}

.icon {
    margin-right: 10px;
}

.benefits {
    text-align: center;
    margin: 20px 0;
    padding: 20px;
}

.icon-big {
    font-size: 25.676px;
    text-align: center;
    display: block;
}

.about-left .btnMore {
    float: left;
}

.faq-wrap {
    width: 95%;
    margin: 20px auto;
}

