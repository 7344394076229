.Services {
    background-color: #F0F6FF;
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    margin: 20px 0;
    border: 1px solid #d5d5d5;
    box-shadow: 5px 5px 35px #e0e6ef;
}
.apImg{
    width: 100%;
    height: 320px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
}
 
@media (max-width: 1024px) {
    .Services {
        width: 100%;
        padding: 10px; 
    } 
}
@media (max-width: 800px) {
    .Services {
        width: 100%;
        padding: 10px; 
    } 
}