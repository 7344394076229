.CarBooking {
    margin: 50px 0;
}

.CarBooking .circle-box {
    background-color: #006CE4;
    color: #F0F6FF;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    margin: 70px auto;
    vertical-align: middle;
    text-align: center;
    padding-top: 50px;
}

.CarBooking .circle-box h5,
.CarBooking .circle-box p {
    color: #F0F6FF;
}
.CarBooking h2 {
    font-weight: 700;
    font-size: 22px;
    padding-top: 20px;
    color: #003B95;
}

.CarBooking p {
    font-size: 14px;
    color: #333;
}

.CarBooking h4 {
    font-size: 18px;
}

.CarBooking .login-center {
    margin: 10px auto;
    display: block;
    text-align: center;
}

.CarBooking .login-center button { 
    border: none;
    margin: 30px 0;
}

.CarBooking .featuredBooking {
    width: 90%;
}

.CarBooking img {
    border-radius: 15px 0 0 15px;
    margin-top: 20px;
}

.CarBooking .booking-details{
    background-color: #F0F6FF;
    border-radius: 0 15px 15px 0;
    margin-top: 20px;
    padding: 12px;
}

.CarBooking .booking-details span {
    font-size: 14px;
}

.CarBooking .paybox {
    border: 1px solid #003B95;
    background: #F0F6FF;
    text-align: center;
    border-radius: 25px;
    padding: 30px;
}

.CarBooking .paybox h4.price {
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 20px;
}

.CarBooking .paybox a {
    text-decoration: none;
}

.CarBooking p.highlight {
    color: #006CE4;
}