.ApplyForRefund h2 {
    font-weight: 700;
    font-size: 25px;
    padding-top: 20px;
    color: #003B95;
}

.ApplyForRefund h3 {
    font-weight: 700;
    font-size: 18px;
    padding-top: 20px;
    color: #003B95;
}

.ApplyForRefund p {
    font-size: 14px;
    color: #333;
}

.ApplyForRefund h4 {
    font-size: 18px;
}

.ApplyForRefund .login-center {
    margin: 10px auto;
    display: block;
    text-align: center;
}

.ApplyForRefund .login-center button { 
    border: none;
    margin: 30px 0;
}

.ApplyForRefund .featuredBooking {
    width: 90%;
}

.ApplyForRefund img {
    border-radius: 15px 0 0 15px;
    margin-top: 20px;
}

.ApplyForRefund .booking-details{
    background-color: #F0F6FF;
    border-radius: 0 15px 15px 0;
    margin-top: 20px;
    padding: 12px;
}

.ApplyForRefund .booking-details span {
    font-size: 14px;
}

.ApplyForRefund .refundbox {
    border-radius: 25px;
    border: 1px solid #FA1A1A;
    background: #FFF6F6;
    text-align: center;
    border-radius: 25px;
    padding: 30px;
}

.ApplyForRefund .refundbox h4.price {
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 20px;
}

.ApplyForRefund .refundbox a {
    text-decoration: none;
}

.ApplyForRefund p.highlight {
    color: #006CE4;
}

.ApplyForRefund .btnMore {
    background: #FA1A1A;
}

.ApplyForRefund .btnMore:hover {
    background: #cb0b0b;
} 