/* Global Styles */

.container {
  width: 90%;
  padding: 35px 0px;
  margin: 0 auto;
}

.header-wrap, .hero {
  width: 100%;
  background-color: #003B95;  
  height: auto;
}

.header {
  width: 90%;
  display: block; 
  margin: 0 auto;
}

.cta {
  float: right;
  padding: 30px 55px;
  font-weight: bold;
  font-size: 14px;
}

.cta-btn {
  background-color: #FFB700;
  color: #F0F6FF;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #F0F6FF;
}

.navbar {
  font-size: 14px;
  height:50px;
  width: 90%;
  display: block; 
  margin: 0 auto;
}

.navbar ul {
  list-style: none;
}

.navbar ul li {
  color: #F0F6FF;
  display: inline;
  padding: 12px 22px 12px 0;
}

.navbar ul li a {
  color: #F0F6FF;
  text-decoration: none;
}

.navbar ul li a.active {
  font-weight: 700;
}

.hero {
  position: relative;
  height: 100vh; 
}

.hero-text-left { 
  padding: 35px 0px; 
  height: 320px; 
}

.hero-text-left h1 {
  font-size: 62px;
  color: #F0F6FF; 
}

.hero-text-left h2 {
  font-size: 42px;
  color: #FFB700; 
}

.hero-text-left p {
  font-size: 18px;
  color: #F0F6FF;
  font-weight: 400;
}

.hero-text-left .btn-hero-wrap {
  width: 100%;
  display: block;
  height: auto;
  padding: 35px 0; 
}

.hero-text-left .btn-hero-wrap .btn-hero-blue {
  color: #F0F6FF;
  border-radius: 15px;
  padding: 15px;
  font-size: 18px;
  font-weight: 700;
  background: #006CE4;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
}

.hero-text-left .btn-hero-wrap .btn-hero-outline {
  color: #F0F6FF;
  border: 1px solid #F0F6FF;
  border-radius: 15px;
  padding: 15px;
  font-size: 18px;
  font-weight: 400; 
  cursor: pointer;
  float: left;
  text-decoration: none;
}

.hero-form-right { 
  margin-top: 0px;
  width: 90%;
  padding: 10px;
  background-color:#006CE4;
  box-shadow: 0px 8px 45px rgba(0, 0, 0, 0.18);
  border-radius: 60px 60px 0px 34px;
}

.hero-form-right .text { 
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #F0F6FF;
  padding: 20px;

}

.breadcrumbs {
  padding: 10px;
}

.breadcrumbs ul {
  margin: 0;
  padding: 0;
}

.breadcrumbs ul li {
  list-style: none;
  display: inline;
}

.breadcrumbs ul li a, .breadcrumbs ul li a.yellow-bg {
  background-color: #003B95;
  display: inline-block;
  background: #F0F6FF; 
  border: 1px solid #F0F6FF;
  text-decoration: none;
  border-radius: 50px 50px 0px 0px;
  padding: 5px 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  width: 124px;
  height: 40px;
}

.breadcrumbs ul li a.yellow-bg{
  background-color: #FFB700; 
  color: #F0F6FF;
}

.hero-form-right .form-panel { 
  width: 100%;
  height: auto;  
  background: #F0F6FF;
  border-radius: 0px 50px;
  padding: 50px 30px 20px;
}

.form-panel p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.form-panel input[type=text],
.form-panel input[type=password],
.form-panel input[type=date] {
  display: block;
  width: 98%;
  height: 33px; 
  /* background: #FFFFFF;   */
  border: 1px solid #006CE4;
  font-size: 16px;
  border-radius: 25px;
  padding: 10px;
}

.form-panel select {
  height: 33px; 
  border: 1px solid #006CE4;
  font-size: 16px;
  border-radius: 25px;
  padding: 10px;
}

label {
  padding: 10px 0px;
  display: inline-block;
}

.number_ct {
  width: 100%;
  display: block;
  margin: 10px;
}

.number_ct label {
  display: inline-block;
}

.number{
  margin-left:10%;
  display: inline-block;
  background: #FFFFFF;  
  border: 1px solid #006CE4;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
}

.minus, .plus{
  width:34px;
  height:34px;
  background:#006CE4;
  color: #F0F6FF;
  padding:3px; 
  border:1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor:pointer; 
}

.minus {
  border-radius:25px 0 0 25px;
}

.plus {  
  border-radius:0 25px 25px 0;
}

.minus:hover, .plus:hover{
  background:#003B95;
  color: #F0F6FF;
}

.number_ct input[type=text]{
  height:34px;
  width: 50px;
  text-align: center;
  font-size: 16px;
  border:none;
  border-radius:4px;
  display: inline-block;
  vertical-align: middle;
}

.form-panel .btn {
  background: #006CE4;
  border-radius: 25px;
  color: #F0F6FF;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  width: 100%;
  margin-top: 20px;
}

.form-panel .btn:hover {
  color: #F0F6FF;
  background: #003B95;
}

/* Typography */


/* List */

@media (max-width: 1000px) {
  .hero { 
    height: auto; 
  }
  .hero-form-right { 
    margin-top: 20px;
  }
  .breadcrumbs ul li {  
    padding: 5px 20px; 
    font-size: 16px;
    line-height: 22px;
    width: 94px;
    height: 30px;
  }

  .hero-text-left .btn-hero-wrap .btn-hero-blue,
  .hero-text-left .btn-hero-wrap .btn-hero-outline {     
    float: left;
    padding: 10px;
    margin: 20px 4px 0 0;
  }
  
}
@media (max-width: 800px) {
  .hero { 
    height: auto; 
  }
  .hero-form-right { 
    margin-top: 20px;
  }
  .breadcrumbs ul li {  
    padding: 5px 20px; 
    font-size: 16px;
    line-height: 22px;
    width: 98px;
    height: 30px;
  }
  .hero-text-left .btn-hero-wrap .btn-hero-blue,
  .hero-text-left .btn-hero-wrap .btn-hero-outline {  
    display: block;
    padding: 10px;
    margin: 20px 4px 0 0; 
  }
}