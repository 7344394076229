.PaymentPage {
    vertical-align: middle;
    text-align: center;
}
.PaymentPage h2 {
    font-weight: 700;
    font-size: 45.82px; 
    padding-top: 20px;
    color: #003B95;
}

.PaymentPage button {
    border: none;
}

.PaymentPage .payment-panel {
    background-color: #F0F6FF;
    padding: 20px;
    /* width: 320px; */
    margin: 50px auto;
    border-radius: 15px;
}
