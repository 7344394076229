.CarListing {
    margin: 50px 0;
}

.CarListing h2 {
    font-weight: 700;
    font-size: 45.82px; 
    padding-top: 20px;
    color: #003B95;
}

.CarListing .cars {
    border: 1px solid #d5d5d5;
    border-radius: 25px;
    height: auto;
    box-shadow: 5px 5px 35px #e0e6ef;
    padding: 30px 0;
}

.CarListing .search-box {
    background: #F0F6FF;
    display: block;
    padding: 25px;
    border-radius: 25px;
}

.CarListing .search-box h4 {
    font-size: 19px;
    font-weight: bold;
}

.CarListing .car-image img {
    /* border: 1px solid #d5d5d5; */
    border-radius: 12px;
    cursor: pointer;
    margin: 5px 2px;

}

.CarListing .car-details .pills {
    border: 1px solid #d5d5d5;
    border-radius: 32px;
    padding: 10px 20px;
    display: inline-block;
    margin-right: 5px;
}

.CarListing .car-details .car-title {
    font-size: 18px;
    font-weight: bold;
    padding: 20px 0;
}

.CarListing .car-details .car-title small {
    font-size: 12px;
    color: #FA1A1A;
    font-weight: normal;
}

.CarListing .car-details .boxicons,
.CarListing .car-details .boxicons-middle {
    padding: 0 5px;
    display: inline-block; 
    text-align: center;
    color: #7e7e7e;
}


.CarListing .car-details .boxicons-middle {
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    padding: 0 8px;
}

.CarListing .car-price {
    display: block;
    padding: 20px;
    background-color: #F0F6FF;
    text-align: center;
    border-radius: 15px;
}
.CarListing .car-price a { text-decoration: none;}

.CarListing .allCars {
    padding-left: 20px;
}

@media (max-width: 1000px) {
    .CarListing .allCars {
        padding-left: 0px;
    }  
}
@media (max-width: 800px) {
    .CarListing .allCars {
        padding-left: 0px;
    }
}