.FlightsListing {
    margin: 0px 0;
}

.FlightsListing .main-hero-wrap {
    background: #FFF; 
}

.FlightsListing .main-hero-wrap .flight-search-panel {
    background: #F0F6FF;
    border-radius: 25px;
    border: 1px solid #FFB700;
    padding: 35px;
}

.FlightsListing .flight-search-panel .form-select,
.FlightsListing .flight-search-panel label {
    margin-top: 20px;
}

.FlightsListing .filter-search { 
    padding: 50px 20px;
    border-radius: 20px;
    margin-top: 30px;
    border: 11px solid #F0F6FF;
}

.FlightsListing .filter-search label {  
    display: flex;
    flex-flow: row;
    justify-items: center;
}

.FlightsListing .filter-search label span {  
    float: right;
    display: block;
}

.FlightsListing .filter-search .bg-btn { 
    background-color: #F0F6FF;
    font-weight: bold;
    margin-right: 19px;
    padding: 10px 10px;
    font-size: 14px;
}

.FlightsListing .search-results .duration {
    display: flex;
    flex-flow: row;
    justify-content: space-between; 
}

.FlightsListing .search-results .duration .time {
    /* border-left: 1px solid #999;
    border-right: 1px solid #999; */
    padding: 0 5px;
}
.FlightsListing .search-results .duration .from, 
.FlightsListing .search-results .duration .to {
    padding: 0 2px;
}

.FlightsListing .tickets {
    border: 5px solid #F0F6FF;
    padding: 20px;
    border-radius: 25px; 
    margin-top: 50px;
}

.FlightsListing .tickets .cta-btn {
    text-align: center;
    background-color: #F0F6FF;
}

.FlightsListing .tickets .cta-btn h5 {
    color: var(--mid-blue-color, #006CE4);
    padding-top: 15px;
}
 
 
@media (max-width: 1024px) {
    .FlightsListing .main-hero-wrap { 
        height: auto;
    }
    
    .FlightsListing .flights-hero-wrap { 
        height: auto;
    }
}
@media (max-width: 800px) {
    .FlightsListing .main-hero-wrap { 
        height: auto;
    }
    
    .FlightsListing .flights-hero-wrap { 
        height: auto;
    }
}