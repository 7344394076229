.TourPreview h2 {
    font-weight: 700;
    font-size: 45.82px; 
    padding-top: 20px;
    color: #003B95;
}

.TourPreview p {
    color: #333;
    font-size: 14px;
}

.TourPreview span {
    color: #333;
    font-size: 14px;
    display: inline-block;
    margin-right: 18px;
}

.TourPreview .main-img {
    border-radius: 25px 0 0 15px;
}

.TourPreview .third-img,
.TourPreview .fourth-img {
    border-radius: 0 25px 25px 0;
}

.TourPreview h3, 
.TourPreview h3 .rental-property,
.TourPreview h3 .landlord {
    font-size: 20px;
    margin: 0;
}

.TourPreview h3 .rental-property,
.TourPreview h3 .landlord {
    color: #003B95;
    font-weight: bold;
}

.TourPreview .landlordImg {
    width: 35px;
    height: 35px;
    border-radius: 100px;
}

.TourPreview .features {
    background-color: #F0F6FF;
    border-radius: 15px;
    padding: 50px 10px;
    border: 1px solid #003B95;
    color: #003B95;
    text-align: center;
    margin: 25px 0;
    font-size: 14px;
}

.TourPreview h4 {
    font-size: 20px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 10px;
    margin: 30px 0;
}

.TourPreview .rules span, 
.TourPreview .offers span {
    color: #003B95;
}

.TourPreview a {
    text-decoration: none;
}

.featBoxImg {
    position: relative;
}

.seeMore {
    color: #003B95;
    border: 1px solid #003B95;
    background-color: #F0F6FF;
    border-radius: 12px;
    position: absolute;
    left: 70px;
    top: 70px;
    padding: 10px;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
}

.TourPreview .reserve-pay {
    background-color: #F0F6FF;
    border-radius: 15px;
    padding: 20px;
    margin-top: 35px;
    text-align: center;
}

@media (max-width: 1024px) {
    .seeMore { 
        left: 20px;
        top: 30px;
        padding: 10px; 
        font-size: 14px;
    }
    .TourPreview .landlordImg {
        width: 25px;
        height: 25px;
    }
    .TourPreview h3, 
    .TourPreview h3 .rental-property,
    .TourPreview h3 .landlord {
        font-size: 16px; 
    }
}
@media (max-width: 800px) {
    .seeMore { 
        left: 50px;
        top: 50px;
        padding: 5px; 
        font-size: 14px;
    }
    .TourPreview .landlordImg {
        width: 25px;
        height: 25px;
    }
    .TourPreview h3, 
    .TourPreview h3 .rental-property,
    .TourPreview h3 .landlord {
        font-size: 16px; 
    }
}
