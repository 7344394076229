nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
    background-color: #003B95;
  }

  .navbar-call { 
    margin: -50px;
    width: 230px;
    float: right;
    color: #F0F6FF;
  }
  .navbar-menu-container {
    display: none;
  }
  .logo { 
    font-size: 1.5rem;
    float: left;
  }
  
  .logoText {
    font-size: 1.5rem;
    color: #F0F6FF;
    font-weight: bold;
  }
  .logoSpan, .coloredText {
    font-weight: bold;
    color: #FFB700;
  }
  .navbar-links-container a {
    margin-right: 1.5rem;
    text-decoration: none;
    color: #F0F6FF;
    margin-top: 15px;
    font-size: .9rem;
    font-weight: 600;
  }
  .primary-button {
    padding: 0.5rem 1.5rem;  
    background-color: #FFB700;
    outline: none;
    border: none;
    border-radius: 3rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
  }
  .navbar-cart-icon {
    font-size: 1.15rem;
  }
  .primary-button:hover {
    background-color: rgb(234, 234, 234);
  }
  .navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    .navbar-links-container a {
      margin-right: 1rem;
      font-size: 1rem;
    }
    .primary-button {
      font-size: 1rem;
    } 

  .navbar-call {display:none}
  }
  @media (max-width: 920px) {
    .nav-logo-container {
      max-width: 140px;
    }
  .navbar-call,
    .navbar-links-container {
      display: none;
    }
    .navbar-menu-container {
      display: flex;
    }
  }