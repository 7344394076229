.app-image-wrap { 
    position: relative;
    width: 100%;
    height: auto; 
    background: #003B95;
    margin-top: 250px;
}

.app-image-wrap .app-text {
    color: #F0F6FF;
    padding: 150px 0;
}

.app-image-wrap .app-text h2 {
    font-size: 21px;
    font-weight: bold;
    padding-bottom: 15px;
}

.app-image-wrap .app-text p {
    font-size: 18px;
    font-weight: 200; 
}

.app-image {
    position: absolute;
    top: -280px; 
    left: -25px;
}

.app-image img{
    width: 100%; 
}

.app-text .app-icons img{
    padding: 20px 0;
    margin-right: 20px; 
    width: 143px;
    height: 83px;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .app-image {
        position: static;
    }
    .app-image img{
        width: 100%; 
    }     
}
@media (max-width: 800px) {
    .app-image img{
        display: none;
    }
}