.Footer {
    padding: 50px 0;
    background-color: #F0F6FF;
}
 
.Footer h2 {
    font-weight: 700;
    font-size: 23px;
    padding-top: 20px;
    color: #003B95;
}
.Footer h3 {
    font-weight: 700;
    font-size: 19px;
    padding-top: 20px;
    color: #003B95;
}
.Footer p { 
    font-style: normal;
    font-weight: 400;
    font-size: 14px;  
    color: #383535;
}

.Footer ul {
    margin: 0;
    padding: 0;
}

.Footer ul li { 
    font-style: normal;
    list-style: none;
}

.Footer .quicklinks ul li a {
    color: #003B95;
    text-decoration: none;
}

.copyright p {
    font-weight: 400;
    font-size: 14px;  
    color: #383535;
    text-align: center;
    padding-top: 50px;
}

.ftLogoText {
    font-size: 1rem;
    color: #003B95;
    font-weight: bold;
}
.coloredText {
    font-weight: bold;
    color: #FFB700;
}