.Subscribe-wrap {
    width: 100%; 
    height: auto; 
    background: #003B95;
    text-align: center;
    color: #FFFFFF;
    padding: 20px;

}
.coloredText {
    font-weight: bolder;
    color: #FFB700;
}
.Subscribe-wrap h2 {
    font-weight: 700;
    color: #F0F6FF;
    font-size: 28px;
    padding-top: 20px;
}
.Subscribe-wrap p { 
    font-style: normal;
    font-weight: 300;
    font-size: 17px;  
    text-align: center;
    color: #FFFFFF;
}

.Subscribe-wrap form{
    margin: 50px auto;
    display: block;
}
.Subscribe-wrap form input[type=email] {
    background-color: #FFFFFF;
    border-radius:  15px 0 0 15px; 
    padding: 10px;
    font-size: 15px;
    width: 250px;  
    border: 2px solid #006CE4;
}

.Subscribe-wrap button {
    background: #006CE4;
    border-radius: 0 15px 15px 0;
    color: #F0F6FF;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    padding: 10px;
    width: 150px;
    border: 1px solid #006CE4;
}

.Subscribe-wrap button:hover {
    color: #F0F6FF;
    background: #003B95;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .Subscribe-wrap form input[type=email] { 
        border-radius:  15px 0 0 15px;  
        margin: 10px auto; 
        width: 250px;   
    }
    
    .Subscribe-wrap button {
        border-radius:  0 15px 15px 0;  
        border-radius:  15px;  
        margin: 10px auto; 
        width: 250px;  
    }
}
@media (max-width: 800px) {
    .Subscribe-wrap form input[type=email] { 
        border-radius:  15px;  
        margin: 10px auto; 
        width: 250px;   
    }
    
    .Subscribe-wrap button {
        border-radius:  15px;  
        margin: 10px auto; 
        width: 250px;  
    }
}