.FlightDeals {
    width: 100%;
    padding: 80px 0;
}

.FlightDeals a {
    text-decoration: none;
}

.plane-image {
    margin-top: -50px;
}

.travelWrap {
    padding: 50px 0 0; 
}
.ticket-img, .travelText {
    float: left;
    padding: 20px;
    border-radius: 25px;
    width: 50%;
}

.btnMore { 
    background: #006CE4;
    border-radius: 25px;
    color: #F0F6FF;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    margin: 0px auto;
    padding: 10px 18px;
    text-align: center;
    float: none;
}

.btnMore:hover {
    color: #F0F6FF;
    background: #003B95;
    cursor: pointer;
}

.ticket-img, .travelText {
    float: left;
    padding: 20px;
    border-radius: 25px;
    width: 50%;
}


@media (max-width: 1000px) {
    .ticket-img, .travelText {
        float: none;
        padding: 20px;
        border-radius: 25px;
        width: 80%;
    }   
}
@media (max-width: 800px) {
    .ticket-img, .travelText {
        float: left;
        padding: 20px;
        border-radius: 25px;
        width: 80%;
    }
}