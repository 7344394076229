.FlightBooking {
    margin: 0px 0;
}

.FlightBooking .main-hero-wrap {
    background: #FFF; 
    /* width: 50%;
    margin: 0 auto; */
}

.FlightBooking .main-hero-wrap h2 {
    color: #006CE4;
    font-size: 45.82px; 
    margin-top: 35px;
}

.FlightBooking .main-hero-wrap .flight-search-panel {
    background: #F0F6FF;
    border-radius: 25px;
    border: 1px solid #FFB700;
    padding: 35px;
}

.FlightBooking .flight-search-panel .form-select,
.FlightBooking .flight-search-panel label {
    margin-top: 20px;
}

.FlightBooking .filter-search { 
    padding: 50px 20px;
    border-radius: 20px;
    margin-top: 30px;
    border: 11px solid #F0F6FF;
}

.FlightBooking .filter-search label {  
    display: flex;
    flex-flow: row;
    justify-items: center;
}

.FlightBooking .filter-search label span {  
    float: right;
    display: block;
}

.FlightBooking .filter-search .bg-btn { 
    background-color: #F0F6FF;
    font-weight: bold;
    margin-right: 19px;
    padding: 10px 10px;
    font-size: 14px;
}

.FlightBooking .search-results .duration {
    display: flex;
    flex-flow: row;
    justify-content: space-between; 
}

.FlightBooking .search-results .duration .time {
    /* border-left: 1px solid #999;
    border-right: 1px solid #999; */
    padding: 0 5px;
}
.FlightBooking .search-results .duration .from, 
.FlightBooking .search-results .duration .to {
    padding: 0 2px;
}

.FlightBooking .tickets {
    border: 5px solid #F0F6FF;
    padding: 20px;
    border-radius: 25px; 
    margin-top: 50px;
}

.FlightBooking .tickets .cta-btn {
    text-align: center;
    background-color: #F0F6FF;
}

.FlightBooking .tickets a {
    text-align: center;
    background-color: #F0F6FF;
    text-decoration: none;
}

.FlightBooking .tickets .cta-btn h5 {
    color: var(--mid-blue-color, #006CE4);
    padding-top: 15px;
}

.FlightBooking .ticket-form { 
    background-color: #F0F6FF;
    padding: 35px;
    border-radius: 15px;
}

.FlightBooking .ticket-form label { 
    margin-top: 15px;
}

.FlightBooking .reserve-pay {
    background-color: #F0F6FF;
    border-radius: 15px;
    padding: 40px 20px;
    margin-top: 35px;
    text-align: center;
}

.reserve-pay a {
    text-decoration: none;
}

 
 
@media (max-width: 1024px) {
    .FlightBooking .main-hero-wrap { 
        height: auto;
    }
    
    .FlightBooking .flights-hero-wrap { 
        height: auto;
    }
}
@media (max-width: 800px) {
    .FlightBooking .main-hero-wrap { 
        height: auto;
    }
    
    .FlightBooking .flights-hero-wrap { 
        height: auto;
    }
}