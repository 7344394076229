.ListAccommodation .form-panel {
    padding: 20px;
    border-radius: 7px;
    border: 0.967px solid #FFF;
    background: #FFFBFB;
    box-shadow: 1.9333332777023315px 3.866666555404663px 19.333332061767578px 0px rgba(0, 0, 0, 0.10);
}

.ListAccommodation .form-panel label { padding-left:10px; display: inline block; }

.ListAccommodation .form-panel .btn {
    width: 150px;
    font-size: 15px;
    margin: 10px auto;
}

.dropbox {
    padding: 20px;
    border-radius: 7px;
    border: 0.967px solid #FFF;
    background: #FFFBFB;
    box-shadow: 1.9333332777023315px 3.866666555404663px 19.333332061767578px 0px rgba(0, 0, 0, 0.10);
}

.dropphotos {
    border: 1px dashed #000;
    background: #FFF;
    text-align: center;
    padding: 70px auto;
    display: block;
}

/* .checkbox {    
    background-color: #003B95;
    padding: 5px;
} */

.btnOutline {
    width: 227.31px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0.736px solid #003B95;
    background: #FFF;
    color: #003B95;
}

.btnOutline:hover {
    background-color: #003B95;
    color: #FFF;
}