.Trends {
    background-color: #fff;
}

.Trends h2 {
    color:#003B95; 
    font-weight: bold;
    padding-bottom: 50px;
}

.box-1, .box-2, .box-3, .box-4,
.box-5, .box-6, .box-7, .box-8,
.box-9, .box-10, .box-11, .box-12
 {
    width: 100%; 
    height: 300px;
    cursor: pointer;
    padding: 8px;
    margin: 20px 0;
    border-radius: 25px;
    background-size: cover;
}
.box-1 {
    background-image: url("../../Assets/Img/apartments.png");
}

.box-2 { 
    background-image: url("../../Assets/Img/hotels.png"); 
}

.box-3 { 
    background-image: url("../../Assets/Img/guesthouses.png"); 
}

.box-4 { 
    background-image: url("../../Assets/Img/villas.png");
}

.box-5 { 
    background-image: url("../../Assets/Img/Suvs.png");
}

.box-6 { 
    background-image: url("../../Assets/Img/SaloonCar.png");
}

.box-7 { 
    background-image: url("../../Assets/Img/AirportTaxi.png");
}

.box-8 { 
    background-image: url("../../Assets/Img/Luxury.png");
}
 
.box-9 { 
    background-image: url("../../Assets/Img/Face.png");
}

.box-10 { 
    background-image: url("../../Assets/Img/Yoga.png");
}

.box-11 { 
    background-image: url("../../Assets/Img/Gym.png");
}

.box-12 { 
    background-image: url("../../Assets/Img/SPA.png");
}

.Trends a {
    text-decoration: none;
}
.cat-title {
    text-align:center;
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    padding: 60px 10px 0;
    margin: 80px auto 0;    
    background: rgb(0, 108, 228, .35);
    color: #F0F6FF;
    font-weight: bold;
    font-size: 16px;
}

.cat-title:hover {
    background: rgba(1, 31, 65, 0.35);
}

.btnMore {
    background: #006CE4;
    border-radius: 25px;
    color: #F0F6FF;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    width: 250px;
    margin: 50px auto;
    padding: 10px 18px;
    text-align: center;
}

.btnMore:hover {
    color: #F0F6FF;
    background: #003B95;
    cursor: pointer;
}