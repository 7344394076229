.Apartments {
    background-color: #F0F6FF;
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    margin: 20px 0;
    border: 1px solid #d5d5d5;
    box-shadow: 5px 5px 35px #e0e6ef;
}
.Apartments .apImg{
    width: 100%;
    height: 320px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
}

.apImg img{
    border-radius: 20px;
    display: none;
}
.apCaption {
    padding-top: 20px;
    font-size: 16px;
    text-transform: uppercase;
}
.apLocation, .apPrice{
    line-height: 5px;
    font-size: 14px;
}
.apLocation { 
  background-color: #FFB700;
  padding: 5px;
  display: block;
}
.apPrice {
    display: block;
    padding: 10px;
    background-color: #003B95;
    color: #F0F6FF;
}
.btnApp { 
    background: #006CE4;
    border-radius: 25px;
    color: #F0F6FF;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; 
    width: 100px;
    float: right;
    padding: 10px 18px;
    text-align: center;
}

.btnApp:hover {
    color: #F0F6FF;
    background: #003B95;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .Apartments {
        width: 100%;
        padding: 10px; 
    }
   .apImg{
        background: none;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        height: 480px; 
    }
}
@media (max-width: 800px) {
    .Apartments {
        width: 100%;
        padding: 10px; 
    }
    .apImg{
        width: 100%;
        background: none;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto; 
    }
    .apImg img{
        display: block;
        border-radius: inherit;
    }
}