.Accommodations h2 {
    font-weight: 700;
    font-size: 45.82px; 
    padding-top: 20px;
    color: #003B95;
}

input[type=text], input[type=number]{
    border: 1px solid #e0e4eb;
}

.search-panel {
    width: 100%;
    height: auto;
    margin: 32px 0;
    background-color: #F0F6FF;
    padding: 30px;
    border: 1px solid #FFB700;
    border-radius: 15px;
}

.msgBox {
    width: 100%;
    height: auto;
    margin: 10px auto; 
    padding: 5px;  
    text-align: center; 
}

.Accommodations .search-panel .btnMore {
    width: 200px;
    margin: 10px 0px;
}
